* {
  font-family:  'Roboto', sans-serif;
}

.app {

}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
