.form_item {
  height: 40px;
  border-radius: 8px !important;
  .ant-select-selector {
    height: 40px !important;
    border-radius: 8px !important;
  }.ant-form-item{
    color: #666 !important;
  }
  .ant-select-selector{
    color: #666 !important;
  }
  .ant-input-number-input{
    color: #666 !important;
  }
}
